import React from 'react';
import '../App.css';
import './TeamSection.css'
import Slideshow from './Slideshow.js'
 

export const SlideshowFR = (props) => {
    return (
        <div className='team-container'>
            <Slideshow/>
            <div className='team-wrapper'>
                <h1 className='doctorName'>{props.doctorName} <br></br> {props.doctorPosition}</h1>
                <br></br>
                <p className='doctorInfo'>{props.doctorInfo}</p>
                {/* <hr style={{height:'1px', 'border-width':0, color:'#D0E3FF', 'background-color': '#D0E3FF'}}></hr> */}
            </div>
            
        </div>
    )
}

export default SlideshowFR;