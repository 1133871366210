import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import TeamSection from '../TeamSection';
import IntroductionCards from '../IntroductionCards';

function Messina() {
  return (
    <>
      <TeamSection doctorName="Dr. Carlo A. Messina" doctorLink='https://www.healthgrades.com/physician/dr-carlo-messina-x7k6g' doctorPosition="D.P.M.Foot and Ankle Surgeon" imgsrc='./images/messina.webp' doctorInfo="Carlo A. Messina D.P.M. is a board-certified foot and ankle surgeon practicing podiatry in West Broward since 2009. He specializes in treating foot, ankle and leg injuries in infants, children, teenagers, and adults. He graduated from the Scholl College of Podiatric Medicine and completed his residency at Rush University Medical Center in Chicago, IL. He also completed a fellowship with the German Foot and Ankle Society in 2009. His interests include treatment of complex deformities in children and adults."/>
      <IntroductionCards/>
      <br></br>
      <br></br>
      <br></br>
      <Footer />
    </>
  );
}

export default Messina;