// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-container{
    width: 500px;
    height: 500px;
    position: relative;
    margin: 10px;
    margin-left: 15%;
}

.each-fade{
    background-color: #fff;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    display: flex;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.leftText{
    text-align: justify;

    /* position: absolute; */
}

.image {
    display: block;
    width: 400px;
    height: 400px;
    margin: auto;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

.react-slideshow-container .default-nav:first-of-type {
    opacity: 0;
}

.react-slideshow-container .default-nav:last-of-type {
    opacity: 0;
}

@media screen and (max-width: 960px) {
    .image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 300px;
        height: 300px;
    }

    .slide-container{
        width: 100%;
        height: 100%;
        margin-left: 0%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Slideshow.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,aAAa;IACb,WAAW;IACX,aAAa;IACb;;0BAEsB;AAC1B;;AAEA;IACI,mBAAmB;;IAEnB,wBAAwB;AAC5B;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,kDAAkD;AACtD;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,cAAc;QACd,iBAAiB;QACjB,kBAAkB;QAClB,YAAY;QACZ,aAAa;IACjB;;IAEA;QACI,WAAW;QACX,YAAY;QACZ,eAAe;IACnB;AACJ","sourcesContent":[".slide-container{\n    width: 500px;\n    height: 500px;\n    position: relative;\n    margin: 10px;\n    margin-left: 15%;\n}\n\n.each-fade{\n    background-color: #fff;\n    margin: 0 auto;\n    padding: 20px;\n    width: 100%;\n    display: flex;\n    /* display: flex;\n    justify-content: center;\n    align-items: center; */\n}\n\n.leftText{\n    text-align: justify;\n\n    /* position: absolute; */\n}\n\n.image {\n    display: block;\n    width: 400px;\n    height: 400px;\n    margin: auto;\n    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;\n}\n\n.react-slideshow-container .default-nav:first-of-type {\n    opacity: 0;\n}\n\n.react-slideshow-container .default-nav:last-of-type {\n    opacity: 0;\n}\n\n@media screen and (max-width: 960px) {\n    .image {\n        display: block;\n        margin-left: auto;\n        margin-right: auto;\n        width: 300px;\n        height: 300px;\n    }\n\n    .slide-container{\n        width: 100%;\n        height: 100%;\n        margin-left: 0%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
