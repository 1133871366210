import React from 'react';
import '../App.css';
import { Button } from './Button';
import './InsuranceSection.css';
import InsuranceSection from './InsuranceSection';
import Footer from './Footer';
import TeamSection from './TeamSection';
 

function Insurance() {
    return (
        <>
            <InsuranceSection/>
            <div className='midsection-container'>
                <div className='insuranceImage'>
                    <h1>Additional Insurance Plans</h1><br></br>
                    <p>Aetna (HMO, PPO, POS, EPO)</p>
                    <p>AvMed (Provider network solution)</p>
                    <p>Better Health</p>
                    <p>Blue Cross/ Blue Shield (PPO, Straight HMO, Federal)</p>
                    <p>Bright</p>
                    <p>Cigna (HMO,PPO, POS)</p>
                    <p>Ambetter</p>
                    <p>United Healthcare Healthy Kids</p>
                    <p>Medicaid</p>
                    <p>US Department of Labor</p>
                    <p>AIG</p>
                    <p>Zurich American</p>
                    <p>Simply Healthcare</p>
                    <p>Amerigroup</p>
                </div>
                
                <br></br>
            
            </div>
            
            <Footer/>
        </>
    )
}

export default Insurance;