import React from 'react';
import '../App.css';
import './TeamSection.css'
import IntroductionCards from './IntroductionCards';
import { Link } from 'react-router-dom';
 

export const TeamSection = (props) => {
    return (
        <div className='team-container'>
            <img className='doctorImage' src={props.imgsrc}></img>
            <div className='team-wrapper'>
            <Link to={`${props.doctorLink}`}><h1 className='doctorName'>{props.doctorName} <br></br> {props.doctorPosition}</h1></Link>
                <p className='doctorInfo'>{props.doctorInfo}</p>
                {/* <hr style={{height:'1px', 'border-width':0, color:'#D0E3FF', 'background-color': '#D0E3FF'}}></hr> */}
            </div>
            
        </div>
    )
}

export default TeamSection;