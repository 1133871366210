import React from 'react';
import '../App.css';
import { Button } from './Button';
import './InsuranceSection.css';
import { Link } from 'react-router-dom';
 

function InsuranceSection() {
    return (
        <div className='midsection'>
            <div className='insurance'>
                    Most Major Insurance Plans Accepted 
            </div>
            <div className='midsection-container'>
                <div className='insuranceImage'>
                    <img className='insurance-image' src="./images/insurance.webp" alt='None'/>
                </div>
                <div className='insurance-button'>
                    <Link to='/insurance'><Button buttonStyle='btn--outline'>All Insurances</Button></Link>
                </div>             
            </div>
            <hr style={{height:'1px', 'border-width':0, color:'#D0E3FF', 'background-color': '#D0E3FF'}}></hr>
        </div>
    )
}

export default InsuranceSection;