import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <img className='hero-picture' src='images/hero.webp'/>
      <h1 className='hero-text'>South Florida’s Premier Sports Foot and Ankle Institute Since 1988 </h1>
      <h3 className='hero-text'>Weston | Plantation </h3>
      <div className='hero-btns'>
        {/* <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          Our Story
        </Button> */}
        <a href='https://www.southfloridasportsmedicine.com/testimonials' target='_blank'>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
        >
          Testimonials
        </Button>
        </a>
      </div>
    </div>
  );
}

export default HeroSection;