// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-wrapper {
  /* display: flex; */
  /* flex-flow: column; */
  align-items: center;
  margin: 20px;
  /* padding: 10px; */
  max-width: 1000px;
  width: 90%;
  /* margin: 0 auto; */
  font-size: 14px;
  text-align: left;
}

.doctorImage{
position: relative;
margin-left: 20%;
max-height: 300px;
max-width: 300px;
}

.doctorPosition, .doctorName {
text-align: left;
}

.team-container {
/* margin: 25px; */
padding: 20px;
margin: 25px;
display: flex;
}

.doctorInfo{
white-space: pre-wrap;
height: 60%;
width: 70%;
}

.doctorPosition, .doctorName{
background: -webkit-linear-gradient(#4B88E4, #5b9dff);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.doctorInfo{
font-family: Ovo, serif
}

@media screen and (max-width: 960px) {
.team-container {
  /* margin: 25px; */
  padding: 20px;
  margin: 25px;
  display: block;
  /* margin: auto; */
}

.doctorInfo{
  font-size: 12px;
  height: 60%;
  width: 100%;
}

.doctorPosition, .doctorName{
  max-height: 400px;
  max-width: 400px;
}

.doctorImage{
  position: relative;
  margin-left: 5%;
  max-height: 200px;
  max-width: 200px;
}
}`, "",{"version":3,"sources":["webpack://./src/components/TeamSection.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,UAAU;EACV,oBAAoB;EACpB,eAAe;EACf,gBAAgB;AAClB;;AAEA;AACA,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;AAChB;;AAEA;AACA,gBAAgB;AAChB;;AAEA;AACA,kBAAkB;AAClB,aAAa;AACb,YAAY;AACZ,aAAa;AACb;;AAEA;AACA,qBAAqB;AACrB,WAAW;AACX,UAAU;AACV;;AAEA;AACA,qDAAqD;AACrD,6BAA6B;AAC7B,oCAAoC;AACpC;;AAEA;AACA;AACA;;AAEA;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;AACA","sourcesContent":[".team-wrapper {\n  /* display: flex; */\n  /* flex-flow: column; */\n  align-items: center;\n  margin: 20px;\n  /* padding: 10px; */\n  max-width: 1000px;\n  width: 90%;\n  /* margin: 0 auto; */\n  font-size: 14px;\n  text-align: left;\n}\n\n.doctorImage{\nposition: relative;\nmargin-left: 20%;\nmax-height: 300px;\nmax-width: 300px;\n}\n\n.doctorPosition, .doctorName {\ntext-align: left;\n}\n\n.team-container {\n/* margin: 25px; */\npadding: 20px;\nmargin: 25px;\ndisplay: flex;\n}\n\n.doctorInfo{\nwhite-space: pre-wrap;\nheight: 60%;\nwidth: 70%;\n}\n\n.doctorPosition, .doctorName{\nbackground: -webkit-linear-gradient(#4B88E4, #5b9dff);\n-webkit-background-clip: text;\n-webkit-text-fill-color: transparent;\n}\n\n.doctorInfo{\nfont-family: Ovo, serif\n}\n\n@media screen and (max-width: 960px) {\n.team-container {\n  /* margin: 25px; */\n  padding: 20px;\n  margin: 25px;\n  display: block;\n  /* margin: auto; */\n}\n\n.doctorInfo{\n  font-size: 12px;\n  height: 60%;\n  width: 100%;\n}\n\n.doctorPosition, .doctorName{\n  max-height: 400px;\n  max-width: 400px;\n}\n\n.doctorImage{\n  position: relative;\n  margin-left: 5%;\n  max-height: 200px;\n  max-width: 200px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
