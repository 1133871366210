import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import TeamSection from '../TeamSection';
import IntroductionCards from '../IntroductionCards';

function Team() {
  return (
    <>
      <IntroductionCards/>
      <br></br>
      <br></br>
      <br></br>
      <Footer />
    </>
  );
}

export default Team;