import React from 'react';
import './Cards.css';
import DoctorCards from './DoctorCards';

function IntroductionCards() {
  return (
    <div className='cards'>
      <h1>Meet the Doctors</h1>
      <br></br>
      <br></br>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <DoctorCards
              src='images/messina.webp'
              text='Dr. Carlo Messina'
              path='/dr-carlo-messina'
            />
            <DoctorCards
              src='images/cohen.webp'
              text='Dr. Michael Cohen'
              path='/dr-michael-cohen'
            />
            <DoctorCards
              src='images/garcia.webp'
              text='Dr. Christina Garcia'
              path='/dr-christina-garcia'
            />
          </ul>
        </div>
      </div>
      <hr style={{height:'1px', 'border-width':0, color:'#D0E3FF', 'background-color': '#D0E3FF'}}></hr>
    </div>
  );
}

export default IntroductionCards;