import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import TeamSection from '../TeamSection';
import Cards from '../Cards';

function Services() {
  return (
    <>
    <Cards/>
      <TeamSection doctorName="Ankle Sprains" imgsrc='./images/white.webp' doctorLink="https://www.southfloridasportsmedicine.com/ankles" doctorInfo="Ankle sprains are caused by an unnatural twisting or force on the ankle bones of the foot, often resulting in one or more ligaments on the outside of the ankle to be stretched or torn. If not properly treated, ankle sprains could develop into long-term problems."/>
      <TeamSection doctorName="Athlete's Foot" imgsrc='./images/white.webp' doctorLink="https://www.southfloridasportsmedicine.com/articles/general/514091-athlete-s-foot" doctorInfo="Corns and calluses are protective layers of compacted, dead skin cells. They are caused by repeated friction from skin rubbing against bony areas or against an irregularity in a shoe. Corns ordinarily form on the toes and calluses on the soles of the feet."/>
      <TeamSection doctorName="Bunions" imgsrc='./images/white.webp' doctorLink="https://www.southfloridasportsmedicine.com/articles/general/bunions" doctorInfo="Bunions are misaligned big toe joints that can become swollen and tender, causing the first joint of the big toe to slant outward, and the second joint to angle toward the other toes."/>
      <TeamSection doctorName="Corns" imgsrc='./images/white.webp' doctorLink="https://www.southfloridasportsmedicine.com/articles/general/corns" doctorInfo="Corns and calluses are protective layers of compacted, dead skin cells. They are caused by repeated friction from skin rubbing against bony areas or against an irregularity in a shoe. Corns ordinarily form on the toes and calluses on the soles of the feet."/>
      <TeamSection doctorName="Diabetes Treatment" imgsrc='./images/white.webp' doctorLink="https://www.southfloridasportsmedicine.com/articles/general/514317-diabetes-and-your-feet" doctorInfo="Ankle surgeries emphasize the realignment of the structure either around or after removal of the deformity. Various kinds of internal and external fixation devices—some temporary, others permanent—are often required to maintain the appropriate alignment during, and beyond, the healing process."/>
      <TeamSection doctorName="Flat Feet" imgsrc='./images/white.webp' doctorLink="https://www.southfloridasportsmedicine.com/articles/general/flat-feet" doctorInfo="Hammertoe is a deformity of the second, third or fourth toes. In this condition, the toe is bent at the middle joint, resembling a hammer. Left untreated, hammertoes can become inflexible and require surgery. "/>
      <TeamSection doctorName="Gait Abnormalities" imgsrc='./images/white.webp' doctorLink="https://www.southfloridasportsmedicine.com/gait-disturbances" doctorInfo="Gait is the pattern that you walk. Sometimes, an injury or underlying medical condition can cause an abnormal gait. You may notice an abnormal gait if you drag your toes when you walk, take high steps or feel off balance when walking. Certain gait abnormalities are temporary and others require lifelong management."/>
      <TeamSection doctorName="Hammertoes" imgsrc='./images/white.webp' doctorLink="https://www.southfloridasportsmedicine.com/articles/general/514484-hammertoes" doctorInfo="Plantar fasciitis (or heel pain) is commonly traced to an inflammation on the bottom of the foot. Our practice can evaluate arch pain and may prescribe customized shoe inserts called orthoses to help alleviate the pain. "/>
      <TeamSection doctorName="Heel Spurs" imgsrc='./images/white.webp' doctorLink="https://www.southfloridasportsmedicine.com/heel-spur" doctorInfo="Ankle surgeries emphasize the realignment of the structure either around or after removal of the deformity. Various kinds of internal and external fixation devices—some temporary, others permanent—are often required to maintain the appropriate alignment during, and beyond, the healing process."/>
      <TeamSection doctorName="Joint Replacement" imgsrc='./images/white.webp' doctorLink="https://www.southfloridasportsmedicine.com/total-ankle-replacement" doctorInfo="Joint replacement surgery is a procedure in which a surgeon removes a damaged joint and replaces it with a new, artificial part. A joint is where two or more bones come together, like the knee, hip, and shoulder. The surgery is usually done by a doctor called an orthopaedic (or-tho-PEE-dik) surgeon. Sometimes, the surgeon will not remove the whole joint, but will only replace or fix the damaged parts."/>
      <TeamSection doctorName="Pediatric Foot and Ankle Conditions" imgsrc='./images/white.webp' doctorLink="https://www.southfloridasportsmedicine.com/childrens-foot-ankle-and-leg-conditions" doctorInfo="With more young people participating in organized sports than ever before, it’s no surprise that the number of pediatric sports injuries is on the rise. While some of these injuries mirror those seen in adult athletes (fractures, sprains, and torn ligaments, to name a few), injury patterns, diagnostic techniques, and choice of treatment can vary significantly in children."/>
      


      <br></br>
      <br></br>
      <br></br>
      <Footer />
    </>
  );
}

export default Services;