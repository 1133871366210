import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Introduction from '../Introduction';
import IntroductionCards from '../IntroductionCards';
import InsuranceSection from '../InsuranceSection';
import Slideshow from '../Slideshow';
import SlideshowFR from '../SlideshowFR.js';
import TeamSection from '../TeamSection';

function Home() {
  return (
    <>
      <HeroSection />
     
      <InsuranceSection/>
      <IntroductionCards/>
      <SlideshowFR doctorName='Welcome To Our Practice' doctorInfo={"At South Florida Foot and Ankle Sports Institute, our commitment is to deliver the most comprehensive and advanced podiatric and rehabilitative care available. Our top priority is the well-being of our patients. Our team of highly skilled physicians, physical therapists, and staff is dedicated to offering patients the latest advancements in medical technology and surgical procedures. We approach medical care with genuine concern for all patients, regardless of their age, from newborns to mature adults.\n\n"  + "Each patient's condition will undergo a thorough evaluation, and we will provide a detailed explanation of the diagnosis and treatment plan. Every patient receives individualized attention and is actively encouraged to ask questions. This website is designed with you, the visitor, in mind. We hope you will find a wealth of information about various musculoskeletal conditions. Our aim for this website is to foster engagement. We invite you to subscribe to our newsletter and share your feedback. We are eager to learn which parts of the website address your questions, what other queries you may have about specific conditions, and any topics of interest you would like us to cover\n\n" +  "Once again, we express our gratitude for visiting our website."}/>
      {/* <Introduction/> */}
      <Cards />
      
      <Footer />
    </>
  );
}

export default Home;