// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notFound {
    margin: 10vh;
}

.notFound-container {
    min-height: 100vh;
    padding: 10px;
    margin: auto;
    width: 800px;
    display: grid;
    justify-content: center;
    align-items: center;
}

.notFound-header {
    font-size: 30px;
}

.notFound-text {
    font-size: 15px;
}

@media screen and (max-width: 960px) { 
    .notFound-container {
        padding: 30px;
        margin: auto;
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/NotFound.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,aAAa;QACb,YAAY;QACZ,WAAW;QACX,aAAa;QACb,uBAAuB;QACvB,mBAAmB;IACvB;;AAEJ","sourcesContent":[".notFound {\n    margin: 10vh;\n}\n\n.notFound-container {\n    min-height: 100vh;\n    padding: 10px;\n    margin: auto;\n    width: 800px;\n    display: grid;\n    justify-content: center;\n    align-items: center;\n}\n\n.notFound-header {\n    font-size: 30px;\n}\n\n.notFound-text {\n    font-size: 15px;\n}\n\n@media screen and (max-width: 960px) { \n    .notFound-container {\n        padding: 30px;\n        margin: auto;\n        width: 100%;\n        display: grid;\n        justify-content: center;\n        align-items: center;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
