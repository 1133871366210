// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.midsection-container {
    font-size: 12px;
    padding: 10px;
    margin: auto;
    width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.midsection {
    padding: 30px;
}

.insurance{
    text-align: center;
}

.insuranceList{
    white-space:pre-wrap;
}


@media screen and (max-width: 960px) { 
    .midsection-container {
        padding: 30px;
        margin: auto;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .insurance-image{
        width: 200px;
        height: 25px;
    }

    /*.insurance-button {
        position: relative;
        left: 0%;
    } */


}`, "",{"version":3,"sources":["webpack://./src/components/InsuranceSection.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;AACxB;;;AAGA;IACI;QACI,aAAa;QACb,YAAY;QACZ,YAAY;QACZ,aAAa;QACb,uBAAuB;QACvB,mBAAmB;IACvB;;IAEA;QACI,YAAY;QACZ,YAAY;IAChB;;IAEA;;;OAGG;;;AAGP","sourcesContent":[".midsection-container {\n    font-size: 12px;\n    padding: 10px;\n    margin: auto;\n    width: 800px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.midsection {\n    padding: 30px;\n}\n\n.insurance{\n    text-align: center;\n}\n\n.insuranceList{\n    white-space:pre-wrap;\n}\n\n\n@media screen and (max-width: 960px) { \n    .midsection-container {\n        padding: 30px;\n        margin: auto;\n        width: 200px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n\n    .insurance-image{\n        width: 200px;\n        height: 25px;\n    }\n\n    /*.insurance-button {\n        position: relative;\n        left: 0%;\n    } */\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
