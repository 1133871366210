import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from './components/Pages/Home';
import Team from './components/Pages/Team';
import Messina from './components/Pages/messina'
import Cohen from './components/Pages/cohen'
import Garcia from './components/Pages/garcia'
import Services from './components/Pages/Services';
import ScrollToTop from './components/ScrollToTop';
import Insurance from './components/Insurance'
import NotFound from './components/NotFound';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop/>
        <Navbar/>
        <Routes>
          <Route path='/' exact element={<Home/>} />
          <Route path='/team' element={<Team/>} />
          <Route path='/dr-carlo-messina' element={<Messina/>} />
          <Route path='/dr-michael-cohen' element={<Cohen/>} />
          <Route path='/dr-christina-garcia' element={<Garcia/>} />
          <Route path='/insurance' element={<Insurance/>} />
          <Route path='/services' element={<Services/>} />
          <Route path='*' element={<NotFound/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
