import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/team'>Meet the Doctors</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Patients</h2>
            <Link to='https://z2-rpw.phreesia.net/referrals.external.app/#/027e3d9e-9454-4075-a9f2-a578b37058dd/patient' target='_blank'>Make an Appointment</Link>
            <Link to='https://2517.portal.athenahealth.com/' target='_blank'>Patient Portal</Link>
            <Link to='https://www.southfloridasportsmedicine.com/testimonials' target='_blank'>Testimonials</Link>
            <Link to='/insurance'>Insurance Accepted</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Locations</h2>
            <Link to='https://goo.gl/maps/8EDFTc8aEWuCbRMr6' target='_blank'>WESTON: <br /> 1600 Town Center Cir Ste C <br /> Weston, FL 33326 <br /> (954) 389-5900</Link>
            <Link to='https://goo.gl/maps/D7mneC3Q4nUQyKRi6' target='_blank'>PLANTATION: <br /> 209 SW 84th Ave <br /> Plantation, FL 33324 <br /> (954) 720 1530</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Office Hours</h2>
            <p style={{ color: '#ffff' }}>Monday: 8:00 A.M. - 5:00 P.M.</p>
            <p style={{ color: '#ffff' }}>Tuesday: 7:30 A.M. - 5:00 P.M.</p>
            <p style={{ color: '#ffff' }}>Wednesday: 7:30 A.M. - 5:00 P.M.</p>
            <p style={{ color: '#ffff' }}>Thursday: 8:00 A.M. - 5:00 P.M.</p>
            <p style={{ color: '#ffff' }}>Friday: 9:00 A.M. - 5:00 P.M.</p>
            <p style={{ color: '#ffff' }}>Saturday, Sunday: CLOSED</p>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <small className='website-rights'>Copyright 2024 © South Florida Foot and Ankle Sports Institute</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link facebook'
              to='https://www.facebook.com/SFISMWeston/'
              target='_blank'
              aria-label='Facebook'
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </Link>
            <Link
              className='social-icon-link instagram'
              to='https://www.instagram.com/southfloridasportsmedicine/'
              target='_blank'
              aria-label='Instagram'
            >
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link
              className='social-icon-link youtube'
              to='https://www.youtube.com/user/southflsportsmed'
              target='_blank'
              aria-label='Youtube'
            >
              <FontAwesomeIcon icon={faYoutube} />
            </Link>
            <Link
              className='social-icon-link twitter'
              to='https://twitter.com/SFLSportsMed'
              target='_blank'
              aria-label='Twitter'
            >
              <FontAwesomeIcon icon={faTwitter} />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
