// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    transition: all 0.3s ease-out;
}

.btn-medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}`, "",{"version":3,"sources":["webpack://./src/components/Button.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,gCAAgC;IAChC,cAAc;IACd,gCAAgC;AACpC;;AAEA;IACI,6BAA6B;IAC7B,WAAW;IACX,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,6BAA6B;AACjC","sourcesContent":[":root {\n    --primary: #fff;\n}\n\n.btn {\n    padding: 8px 20px;\n    border-radius: 2px;\n    outline: none;\n    border: none;\n    cursor: pointer;\n}\n\n.btn--primary {\n    background-color: var(--primary);\n    color: #242424;\n    border: 1px solid var(--primary);\n}\n\n.btn--outline {\n    background-color: transparent;\n    color: #fff;\n    padding: 8px 20px;\n    transition: all 0.3s ease-out;\n}\n\n.btn-medium {\n    padding: 8px 20px;\n    font-size: 20px;\n}\n\n.btn--large {\n    padding: 12px 26px;\n    font-size: 20px;\n}\n\n.btn--medium:hover, .btn--large:hover {\n    background: #fff;\n    color: #242424;\n    transition: all 0.3s ease-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
