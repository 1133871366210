import React from "react";
import './Button.css'
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline'];

const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({
    children, 
    type, 
    onClick, 
    buttonStyle, 
    buttonSize
    }) => {
        const checkButtonStyle = STYLES.includes(buttonStyle)
        ? buttonStyle 
        : STYLES[0];

        const checkButtonSize = SIZES.includes(buttonSize) 
        ? buttonSize 
        : SIZES[0];

        return (
            // <Link to='https://z2-rpw.phreesia.net/referrals.external.app/#/027e3d9e-9454-4075-a9f2-a578b37058dd/patient' target="_blank" className='btn-mobile'>
                <button
                    className={'btn ${checkButtonStyle} ${checkButtonSize}'}
                    onClick={onClick}
                    type={type}
                >
                    {children}
                </button>
                

            // </Link>
        )
    };