// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intro {
  background: #ffffff;
  padding: 20px;
}

.intro-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  
  text-align: center;
}

.introText {
color: #000000;
font-family: 'Playfair Display', serif;
font-size: 14px;
font-weight: 100;
}`, "",{"version":3,"sources":["webpack://./src/components/Introduction.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;EACjB,UAAU;EACV,cAAc;;EAEd,kBAAkB;AACpB;;AAEA;AACA,cAAc;AACd,sCAAsC;AACtC,eAAe;AACf,gBAAgB;AAChB","sourcesContent":[".intro {\n  background: #ffffff;\n  padding: 20px;\n}\n\n.intro-container {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  max-width: 1120px;\n  width: 90%;\n  margin: 0 auto;\n  \n  text-align: center;\n}\n\n.introText {\ncolor: #000000;\nfont-family: 'Playfair Display', serif;\nfont-size: 14px;\nfont-weight: 100;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
